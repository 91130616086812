<template>
  <div class="modal fade" id="welcomeBack" tabindex="-1" aria-labelledby="welcomeBackLabel" aria-hidden="true"
       data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="welcomeBackLabel"></h5>
        </div>
        <div class="modal-body">
          <p class="mb-1 text-center">{{ $t('welcomeBack.yayBack', {name: name}).trim() }}!</p>
          <p class="text-center mb-1">{{ $t('welcomeBack.letsHaveSomeFun') }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn custom-btn btn-success py-2 px-2 my-3" data-bs-dismiss="modal"
                  v-on:click="closeModal()">{{ $t('common.letsPlay') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../scss/modal.scss";

export default {
  components: {},
  name: "WelcomeBack",
  props: {
    clickAction: {type: Function},
    name: {type: String},
  },
  methods: {
    closeModal() {
      this.clickAction();
    }
  }
};
</script>
