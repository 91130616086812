import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.min.css';
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import {i18n} from "@/i18n";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";


Vue.config.cd = false

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const firebaseConfig = {
    apiKey: "AIzaSyB8pT6JEQ-OmXpxtAYIxN9Dyc6PBN4hIYI",
    authDomain: "funnector-website.firebaseapp.com",
    projectId: "funnector-website",
    storageBucket: "funnector-website.appspot.com",
    messagingSenderId: "934944389758",
    appId: "1:934944389758:web:c8e4831ae496238dd6b62f",
    measurementId: "G-YH7CNDGWB2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

Vue.use(VueCookies);
Vue.use(VueAxios, axios)

new Vue({
    router,
    i18n,
    render: h => h(App)
})
    .$mount('#app')

