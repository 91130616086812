<template>
  <div v-show="!isLoading" class="game-container">
    <div class="game">
      <div class="toast-container">
        <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true" ref="toast">
          <div class="toast-body" ref="toastMessage"></div>
        </div>
      </div>
      <div class="board" ref="board">
        <div v-for="t in triesCount" :key="t" class="" ref="boardRow">
        <span v-for="l in wordLettersCount" :key="t+l" ref="letters"
              class=""></span>
        </div>
      </div>
    </div>

    <div class="keyboard" ref="keyboardLetters">
      <div v-for="(list, idx) in keyboard" :key="idx" class="d-flex justify-content-center">
        <template v-for="(k, kIdx) in list">
          <div v-if="k === ''" :key="kIdx" class="gap"></div>
          <span v-else-if="k === 'ent'" :key="kIdx" v-on:click="checkCorrectness"
                class="d-flex align-items-center justify-content-center enter">{{ $t("play.enter") }}</span>
          <span v-else-if="k === 'del'" :key="kIdx" v-on:click="removeLetter"
                class="d-flex align-items-center justify-content-center remove">
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.7939 6.55826C10.5011 6.26535 10.5011 5.7905 10.7939 5.49759C11.0869 5.20468 11.5618 5.20468 11.8546 5.49759L14.3296 7.9725L16.8044 5.49759C17.0973 5.20468 17.5721 5.20468 17.8651 5.49759C18.158 5.79044 18.158 6.26535 17.8651 6.55826L15.3901 9.03311L17.8651 11.508C18.158 11.8009 18.158 12.2758 17.8651 12.5686C17.5721 12.8615 17.0973 12.8615 16.8044 12.5686L14.3296 10.0938L11.8546 12.5686C11.5618 12.8615 11.0869 12.8615 10.7941 12.5686C10.5011 12.2757 10.5011 11.8009 10.7941 11.508L13.2689 9.03317L10.7939 6.55826Z"
                fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.70093 1.41177L0.0795898 9.03311L7.70093 16.6544C8.26355 17.2171 9.02661 17.5331 9.82227 17.5331H20.0796C21.7365 17.5331 23.0796 16.19 23.0796 14.5331V3.53311C23.0796 1.87625 21.7365 0.533112 20.0796 0.533112H9.82227C9.02661 0.533112 8.26355 0.849213 7.70093 1.41177ZM20.0796 16.2331H9.82227C9.37134 16.2331 8.93896 16.054 8.62012 15.7352L1.91809 9.03311L8.62012 2.33102C8.93896 2.01224 9.37134 1.8331 9.82227 1.8331H20.0796C21.0184 1.8331 21.7795 2.59421 21.7795 3.53311V14.5331C21.7795 15.472 21.0184 16.2331 20.0796 16.2331Z"
                  fill="black"/>
          </svg>
          </span>
          <span v-else v-on:click="onLetterInput(k)" :key="kIdx" :data-letter="k.toUpperCase()"
                class="d-flex align-items-center justify-content-center">{{ k }}</span>
        </template>
      </div>
    </div>
    <GameGuide :inviter-name="inviterName" :relationType="relationType" :player-name="playerName" :self-invited="selfInvited"></GameGuide>
    <HintModal :hint="hint"></HintModal>
    <ResultModal
        :success="success"
        :inviter-name="inviterName"
        :player-name="playerName"
        :relation-type="relationType"
        :success-level="successLevelMessage"
        :on-close="showThanks"
        :self-invited="selfInvited"
        :tries="currentTry + 1"
    ></ResultModal>
    <Thanks></Thanks>
  </div>
</template>
<script>
import "../scss/play.scss";
import GameGuide from "./GameGuide";
import HintModal from "./HintModal";
import ResultModal from "./ResultModal";
import {Toast, Modal} from 'bootstrap';
import {enp1, enp2, esp1, esp2, esp3} from './ValidWords';
import Thanks from "@/components/Thanks";
import {loadLanguageAsync} from "@/i18n";

export default {
  components: {
    GameGuide,
    HintModal,
    ResultModal,
    Thanks,
  },
  name: "Player",
  data() {
    return {
      isLoading: true,
      wordLettersCount: 5,
      triesCount: 6,
      currentTry: 0,
      index: 0,
      currentWord: '',
      correctWord: 'MAPLE',
      gameId: null,
      hint: "",
      hintIndex: 4,
      success: true,
      isFinished: false,
      selfInvited: false,
      keyboardLayouts: {
        en: [
          ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
          ['', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ''],
          ['ent', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'del']
        ],
        es: [
          ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
          ['', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ', ''],
          ['ent', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'del']
        ]
      },
      playerName: "",
      inviterName: "",
      relationType: ""
    };
  },

  created() {
    this.isLoading = true;
    this.gameId = this.$route.params.gameId;
    this.axios.get("/actions/game/" + this.gameId).then((response) => {
      if (response.data.success) {
        this.correctWord = response.data.data.word.toUpperCase();
        this.hint = response.data.data.hint;
        this.playerName = response.data.data.playerName;
        this.inviterName = response.data.data.inviterName;
        this.relationType = response.data.data.relationType;
        this.selfInvited = response.data.data.selfInvited;
        loadLanguageAsync(response.data.data.locale)
        this.loadHistory();

        if (this.$cookies.isKey("gameFinished_" + this.gameId)) {
          this.isFinished = true;
          this.success = this.$cookies.get("gameStatus_" + this.gameId) === "true";
          this.currentTry = parseInt(this.$cookies.get("gameFinishedAt_" + this.gameId) ?? 0);
          this.isLoading = false;
          this.showResultBanner(false);
        } else {
          this.isLoading = false;
          this.componentCreated();
        }
      }
    })
  },

  computed: {
    gameResultKey() {
      return "gameResult_" + this.gameId
    },

    successLevelMessage() {
      switch (this.currentTry) {
        case 0:
          return "Outstanding";
        case 1:
          return "Excellent";
        case 2:
          return "Amazing";
        case 3:
          return "Awesome";
        case 4:
          return "Great";
        default:
          return "Phew"
      }
    },

    keyboard() {
      return this.keyboardLayouts[this.$i18n.locale] ?? this.keyboardLayouts['en'];
    },

    validLettersRegex() {
      switch (this.$i18n.locale) {
        case 'es':
          // return "^[a-zñáéíóúü]$/i"
          return "^[a-zA-ZñÑ]$"
        default:
          return "^[a-zA-Z]$"
      }
    }
  },

  methods: {
    componentCreated() {
      window.addEventListener("keyup", e => {
        if (e.keyCode === 8) {
          this.removeLetter();
          return;
        } else if (e.keyCode === 13) {
          this.checkCorrectness();
          return;
        }

        this.onLetterInput(String.fromCharCode(!e.charCode ? e.which : e.charCode));
      });
    },
    onLetterInput(letter) {
      var regex = new RegExp(this.validLettersRegex);
      if (this.isFinished || !regex.test(letter) || this.currentWord.length === this.wordLettersCount) {
        return false;
      }

      this.currentWord += letter.toUpperCase();
      this.$refs.letters[this.index].textContent = letter;
      this.$refs.letters[this.index].classList.add('active');
      this.index++;
    },
    removeLetter() {
      if (this.isFinished || !this.currentWord.length) {
        return false;
      }

      this.index--;
      this.$refs.letters[this.index].textContent = "";
      this.$refs.letters[this.index].classList.remove('active');
      this.currentWord = this.currentWord.slice(0, -1);
      this.$refs.boardRow[this.currentTry].classList.remove("shake");
    },
    checkCorrectness() {
      if (this.currentWord.length !== this.wordLettersCount) {
        this.showMessage(this.$t("play.notEnoughLetters"));
        return false;
      }

      if (!this.isValidWord(this.currentWord)) {
        this.showMessage(this.$t("play.notInWordList"));
        this.$refs.boardRow[this.currentTry].classList.add("shake");
        return false;
      } else {
        this.$refs.boardRow[this.currentTry].classList.remove("shake");
      }

      let correctCounts = 0;
      let pos = this.index - (this.currentWord.length);
      let result = {};
      let historyResult = {};
      for (let i = 0; i < this.currentWord.length; i++) {
        let c = this.currentWord.charAt(i)
        if (result[c] === undefined) {
          result[c] = {};
        }

        this.$refs.letters[pos].classList.remove('active');
        if (this.correctWord.indexOf(c) < 0) {
          result[c][pos] = 'wrong';
        } else if (c === this.correctWord.charAt(i)) {
          result[c][pos] = 'correct';
          correctCounts++;
        } else if (this.correctWord.indexOf(c) === this.correctWord.lastIndexOf(c) && this.currentWord.indexOf(c) !== this.currentWord.lastIndexOf(c)) {
          result[c][pos] = 'unknown';
        } else if (this.correctWord.indexOf(c) !== this.correctWord.lastIndexOf(c) && this.currentWord.indexOf(c) !== this.currentWord.lastIndexOf(c)) {
          result[c][pos] = 'unknown';
        } else if (this.currentWord.indexOf(c) !== this.correctWord.lastIndexOf(c)) {
          result[c][pos] = 'misplaced';
        } else {
          result[c][pos] = 'wrong';
        }

        pos++;
      }

      for (let c in result) {
        if (Object.keys(result[c]).length > 1) {
          let picked = 0;
          const letterCount = this.correctWord.split(c).length - 1;
          for (let p in result[c]) {
            if (result[c][p] === "unknown") {
              result[c][p] = picked >= letterCount || letterCount === 1 ? "wrong" : "misplaced";
              picked++;
            }
          }
        }

        for (let p in result[c]) {
          this.$refs.letters[p].classList.remove("wrong", "correct", "misplaced");
          this.$refs.letters[p].classList.add(result[c][p]);
          this.$refs.keyboardLetters.querySelector('[data-letter="' + c + '"]').classList.remove("wrong", "correct", "misplaced");
          this.$refs.keyboardLetters.querySelector('[data-letter="' + c + '"]').classList.add(result[c][p]);

          historyResult[p] = {
            "letter": c,
            "status": result[c][p]
          };
        }
      }

      this.saveInHistory(historyResult);

      if (correctCounts === this.wordLettersCount) {
        this.success = true;
        // let t = "";
        // this.showMessage(t);
        setTimeout(function () {
          this.showResultBanner()
        }.bind(this), 1500);
      } else {
        if (this.currentTry === this.triesCount - 1) {
          this.success = false;
          this.showMessage(this.correctWord, 2000);
          setTimeout(function () {
            this.showResultBanner();
          }.bind(this), 2000);
          return;
        }

        this.currentWord = "";
        this.currentTry++;

        if (!this.isFinished && this.currentTry === this.hintIndex && this.hint.length > 0) {
          const hintModal = new Modal(document.getElementById('hintModal'));
          const modalToggle = document.getElementById('hintModal');
          hintModal.show(modalToggle);
        }
      }
    },
    isValidWord(guess) {
      guess = guess.toLowerCase();
      if (this.correctWord.toLowerCase() === guess) {
        return true;
      }

      if (this.$i18n.locale === 'es' && (esp1.includes(guess) || esp2.includes(guess) || esp3.includes(guess))) {
        return true;
      }

      return enp1.includes(guess) || enp2.includes(guess);
    },
    saveResult() {
      this.isFinished = true;
      this.$cookies.set("gameFinished_" + this.gameId, true);
      this.$cookies.set("gameStatus_" + this.gameId, this.success);
      this.$cookies.set("gameFinishedAt_" + this.gameId, this.currentTry);
    },
    showResultBanner(saveResult = true) {
      if (saveResult) {
        this.saveResult();
      }

      const resultModal = new Modal(document.getElementById('resultModal'));
      const modalToggle = document.getElementById('resultModal');
      resultModal.show(modalToggle);
    },
    loadHistory() {
      if (this.$cookies.isKey(this.gameResultKey)) {
        const history = this.$cookies.get(this.gameResultKey);
        for (let h in history) {
          this.$refs.letters[this.index].textContent = history[h]["letter"];
          this.$refs.letters[this.index].classList.remove("wrong", "correct", "misplaced");
          this.$refs.letters[this.index].classList.add(history[h]["status"]);
          this.$refs.keyboardLetters.querySelector('[data-letter="' + history[h]["letter"] + '"]').classList.remove("wrong", "correct", "misplaced");
          this.$refs.keyboardLetters.querySelector('[data-letter="' + history[h]["letter"] + '"]').classList.add(history[h]["status"]);
          this.index++;
        }

        this.currentTry = this.index > 0 ? Math.floor(this.index / this.wordLettersCount) : 0;
        if (this.currentTry === this.hintIndex && this.hint.length > 0) {
          const hintModal = new Modal(document.getElementById('hintModal'));
          const modalToggle = document.getElementById('hintModal');
          hintModal.show(modalToggle);
        }
      }
    },
    saveInHistory(result) {
      let data = {};
      if (this.$cookies.isKey(this.gameResultKey)) {
        data = this.$cookies.get(this.gameResultKey);
      }

      data = {...data, ...result};
      this.$cookies.set(this.gameResultKey, JSON.stringify(data));
    },
    showMessage(msg, delay = 3000) {
      this.$refs.toastMessage.textContent = msg;
      let t = new Toast(this.$refs.toast, {
        delay: delay
      })
      t.show()
    },
    showThanks() {
      const modal = new Modal(document.getElementById("thanksModal"));
      const modalToggle = document.getElementById("thanksModal");
      modal.show(modalToggle);
    },
  },
};
</script>
