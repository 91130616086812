<template>
  <div class="modal fade" id="gameGuide" tabindex="-1" aria-labelledby="gameGuideLabel" aria-hidden="true"
       data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="gameGuideLabel"></h5>
        </div>
        <div class="modal-body" itemprop="description">
          <p v-if="name !== ''" class="mb-4"><strong>{{ $t("common.namedWelcome", {name: name}) }}</strong></p>
          <p v-else  class="mb-4"><strong>{{ $t("common.welcome") }}</strong></p>
          <p class="mb-1" v-html="$t('homeGuide.description')"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn custom-btn btn-success py-2 px-2 my-3" data-bs-dismiss="modal"
                  v-on:click="closeModal()">{{ $t("common.letsPlay") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../scss/modal.scss";
import {setInviterVisited} from "./Storage";

export default {
  components: {},
  name: "HomeGuide",
  props: {
    clickAction: {type: Function},
    name: {type: String},
  },

  methods: {
    closeModal() {
      setInviterVisited();
      this.clickAction();
    }
  }
};
</script>
