import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/locales/messages'

Vue.use(VueI18n)
const defaultLocale = 'en';

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages,
})

const loadedLanguages = ['en', 'es'];

function setI18nLanguage(lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    if (lang === "" || lang === undefined || lang === null) {
        lang = defaultLocale;
    }

    lang = lang.toLowerCase();
    if (lang !== defaultLocale) {
        const mapCountryToLang = {
            MX: 'es',
            CO: 'es',
            ES: 'es',
            AR: 'es',
            PE: 'es',
            VE: 'es',
            CL: 'es',
            GT: 'es',
            EC: 'es',
            BO: 'es',
            CU: 'es',
            DO: 'es',
            HN: 'es',
            PY: 'es',
            SV: 'es',
            NI: 'es',
            CR: 'es',
            PA: 'es',
            UY: 'es',
            GQ: 'es'
        };

        lang = mapCountryToLang[lang.toUpperCase()] ?? defaultLocale;
    }

    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.js`).then(
    //     messages => {
    //         console.log(messages.default);
    //         i18n.setLocaleMessage(lang, messages.default)
    //         loadedLanguages.push(lang)
    //         return setI18nLanguage(lang)
    //     }
    // )
}