export default {
    en: {
        common: {
            hello: "Hello {name}",
            helloThere: "Hello there!",
            namedWelcome: "Welcome {name}!",
            welcome: "Welcome to Funnector!",
            letsGo: "Let's Go",
            letsPlay: "Let's Play",
            gotIt: "Got it",
            notNow: "Not Now",
            yes: "Yes",
            back: "Back",
            next: "Next",
            goToMag: "Go to Mag",
            livingMaplesGame: "a Living Maples game",
            challengeSomeone: "Challenge someone",
            playAgain: "Play Again",
        },
        gameGuide: {
            namedInvitation: "Your {relationType}, {inviterName}, invited you to Funnector. Here's how to play: {inviterName}, picked a five-letter word. @:(gameGuide.description)",
            unnamedInvitation: "Funnector has a challenge ready for you. Here's how to play: Your loved one picked a five-letter word. @:(gameGuide.description)",
            selfInvitation: "Funnector has a challenge ready for you. Here's how to play: We picked a five-letter word. @:(gameGuide.description)",
            description: "Guess the word riddle in six tries. After each guess, the colour of the tiles will change to show how close your guess was to the word.",
            wrongLetter: "The letter is not in the word in any spot.",
            misplacedLetter: "The letter is correct but <strong>in the wrong spot</strong>.",
            correctLetter: "Great! Correct letter <strong>in the right place</strong>.",
        },
        hint: {
            note: "You didn't find it yet, it seems.<br>No worries, though. Here is a clue:"
        },
        home: {
            modeSelectionDescription: "Funnector welcomes you!<br>It's a simple game that you can play on your own or with a loved one.<br>Test it out and see if you like it. Then play it with your friends or family because fun connects!",
            playNow: "Play Now",
            challengeSomeone: "Challenge someone",
            nameLabel: "May I ask what's your name?",
            nameHint: "If you don't like to enter your name, you can leave it empty. It's just fun :)",
            wordDescription: "Pick a five-letter word, something that rings a bell.",
            wordLabel: "Now enter it here:",
            wordError: "Sorry! Your word is empty or too short.",
            hintDescription: "Do you wish to help them a little bit?",
            hintLabel: "You can give them a short hint:",
            hintError: "Your hint should not be longer than {maxHintLength} words.",
            playerRelationLabel: "The person you want to play with is your:",
            relationFriend: "Friend",
            relationSpouse: "Spouse",
            relationChild: "Child",
            relationParent: "Parent",
            relationGrandchild: "Grandchild",
            relationGrandparent: "Grandparent",
            relationSibling: "Sibling",
            playerNameLabel: "Please write his/her name:",
            playerNameHint: "This field is optional, too.",
            youAreReady: "Great! You did it.",
            readyToInviteWithName: "You can now invite your {relationType}, {playerName} to guess {theWord}.<br>Whatsapp will open after you tap Invite. Please select {playerName} and send the invitation.<br>You can edit the invite message if you want.<br>Fun connects!",
            readyToInvite: "You can now invite any of your loved ones to guess {theWord}.<br>Whatsapp will open after you tap Invite. Please select someone you want to play with and send the invitation.<br>You can edit the invite message if you want.<br>Fun connects!",
            invitedWithName: "You invited your {relationType}, {playerName}, to guess the word {theWord}.",
            invited: "You have sent the invitation. Now, wait and see if they can guess the word {theWord}.",
            playWithSomeoneElse: "Do you like to play this game with someone else too?",
            theSameWord: "The Same Word",
            newWord: "New Word",
            invite: "Invite",
            invitationMessageWithName: "Hi {playerName}\n@:(home.invitationMessageFooter)",
            invitationMessage: "Hey, what's up?\n@:(home.invitationMessageFooter)",
            invitationMessageFooter: "I picked a 5-letter word riddle for you on Funnector. Let's see if you can guess it :)\n{link}\nP.S. Don't worry. It's just a game; easy to play, hard to guess.\n",
        },
        homeGuide: {
            description: "Funnector's here!<br>Simple and fun, a game for everyone :)<br>You pick a word, choose someone you want to play with, and they will try to guess that word.<br><br>Would you like to give it a try?"
        },
        play: {
            enter: "ENTER",
            notEnoughLetters: "Not enough letters",
            notInWordList: "Not in word list",
        },
        resultModal: {
            challengeOthers: "Now it's your turn.<br>Do you like to challenge someone?",
            selfInvitedChallengeOthers: "What a master you are. Would you like to challenge someone with a word?",
            tellResultToInviterWithName: "BTW don't forget to tell your {relationType}, {inviterName}, that you found it.",
            wantToShareResult: "I want to share my success",
            successMessage: "That was fun. Keep it coming 😎",
            failMessage: "Aw! I didn't get it. Let's try again 😊",
        },
        thanks: {
            message: "Hope you liked Funnector!<br>Take a look at our magazine if you like."
        },
        welcomeBack: {
            yayBack: "Yay! You're back {name}",
            letsHaveSomeFun: "Let's have some more fun."
        }
    },
    es: {
        common: {
            hello: "Hola {name}",
            helloThere: "¡Hola ahi!",
            namedWelcome: "¡Bienvenido, {name}!",
            welcome: "¡Bienvenido a Funnector!",
            letsGo: "¡Vámonos!",
            letsPlay: "Vamos a jugar",
            gotIt: "¡Entendido",
            notNow: "Ahora no",
            yes: "sí",
            back: "Volver",
            next: "Siguiente",
            goToMag: "Ir a Revista",
            livingMaplesGame: "un juego de Living Maples",
            challengeSomeone: "Desafíar a alguien",
            playAgain: "Juega de nuevo",
        },
        gameGuide: {
            namedInvitation: "Tu {relationType}, {inviterName}, te invitó a Funnector. Así es como se juega: {inviterName} escogió una palabra de cinco letras. @:(gameGuide.description)",
            unnamedInvitation: "Funnector tiene un desafío listo para ti. Así es como se juega: su ser querido eligió una palabra de cinco letras. @:(gameGuide.description)",
            selfInvitation: "Funnector tiene un desafío listo para ti. Así es como se juega: Elegimos una palabra de cinco letras. @:(gameGuide.description)",
            description: "Adivina la palabra acertijo en seis intentos. Después de cada suposición, el color de las fichas cambiará para mostrar qué tan cerca estuvo su suposición de la palabra.",
            wrongLetter: "La letra no está en la palabra en ningún lugar.",
            misplacedLetter: "La letra es correcta pero <strong>en el lugar equivocado</strong>.",
            correctLetter: "¡Genial! Letra correcta <strong>en el lugar correcto</strong>.",
        },
        hint: {
            note: "Parece que todavía no lo encontraste.<br>Sin embargo, no te preocupes. Aquí hay una pista:"
        },
        home: {
            modeSelectionDescription: "¡Funnector te da la bienvenida!<br>Es un juego simple que puedes jugar solo o con un ser querido.<br>Pruébalo y ve si te gusta. ¡Luego juegue con sus amigos o familiares porque la diversión se conecta!",
            playNow: "Reproducir ahora",
            challengeSomeone: "Desafíar a alguien",
            nameLabel: "¿Puedo preguntar cuál es tu nombre?",
            nameHint: "Si no desea ingresar su nombre, puede dejarlo vacío. Es divertido :)",
            wordDescription: "Elige una palabra de cinco letras, algo que te suene.",
            wordLabel: "Ahora introdúcelo aquí:",
            wordError: "¡Lo siento! Tu palabra está vacía o es demasiado corta.",
            hintDescription: "¿Desea ayudarles un poco?",
            hintLabel: "Puedes darles una pequeña pista:",
            hintError: "Su sugerencia no debe tener más de {maxHintLength} palabras.",
            playerRelationLabel: "La persona con la que quieres jugar es tu:",
            relationFriend: "Amigue",
            relationSpouse: "Cónyuge",
            relationChild: "Niñe",
            relationParent: "Padres",
            relationGrandchild: "El nieto",
            relationGrandparent: "Abuel",
            relationSibling: "Herman",
            playerNameLabel: "Por favor escriba su nombre:",
            playerNameHint: "Este campo también es opcional.",
            youAreReady: "¡Genial! Lo hiciste.",
            readyToInviteWithName: "Ahora puede invitar a su {relationType}, {playerName}, a adivinar {theWord}.<br>Whatsapp se abrirá después de tocar Invitar. Seleccione a John y envíe la invitación.<br>Puede editar el mensaje de invitación si lo desea.<br>¡La diversión se conecta!",
            readyToInvite: "Ahora puede invitar a cualquiera de sus seres queridos a adivinar {theWord}.<br>Whatsapp se abrirá después de tocar Invitar. Selecciona a alguien con quien quieras jugar y envía la invitación.<br>Puedes editar el mensaje de invitación si quieres.<br>¡La diversión se conecta!",
            invitedWithName: "Invitaste a tu {relationType}, {playerName}, a adivinar la palabra {theWord}.",
            invited: "Has enviado la invitación. Ahora, espere y vea si pueden adivinar la palabra {theWord}.",
            playWithSomeoneElse: "¿Te gusta jugar este juego con otra persona también?",
            theSameWord: "la misma palabra",
            newWord: "Palabra nueva",
            invite: "Invitación",
            invitationMessageWithName: "Hola {playerName}\n@:(home.invitationMessageFooter)",
            invitationMessage: "¿Hola! Qué tal?\n@:(home.invitationMessageFooter)",
            invitationMessageFooter: "Elegí un acertijo de palabras de 5 letras para ti en Funnector. A ver si lo adivinas :)\n{link}\nPD No te preocupes. Es solo un juego; fácil de jugar, difícil de adivinar.\n",
        },
        homeGuide: {
            description: "¡Funnector está aquí!<br>Simple y divertido, un juego para todos :)<br>Eliges una palabra, eliges a alguien con quien quieras jugar y ellos intentarán adivinar esa palabra.<br><br>¿Te gustaría ¿Darle una oportunidad?"
        },
        play: {
            enter: "ENTER",
            notEnoughLetters: "No hay suficientes letras",
            notInWordList: "No en la lista de palabras",
        },
        resultModal: {
            challengeOthers: "Ahora es tu turno.<br>¿Te gusta desafiar a alguien?",
            tellResultToInviterWithName: "Por cierto, no olvides decirle a tu {relationType}, {inviterName}, que lo encontraste.",
            wantToShareResult: "Quiero compartir mi éxito",
            successMessage: "Eso fue divertido. Sigue así 😎",
            failMessage: "¡Ay! no lo entendí Intentemos de nuevo 😊",
        },
        thanks: {
            message: "¡Espero que te haya gustado Funnector!<br>Echa un vistazo a nuestra revista si quieres."
        },
        welcomeBack: {
            yayBack: "¡Hurra! Has vuelto {name}",
            letsHaveSomeFun: "Divirtámonos un poco más."
        }
    }
}