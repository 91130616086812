<template>
  <div class="modal fade" id="resultModal" tabindex="-1" aria-labelledby="resultModalLabel" aria-hidden="true"
       ref="resultModalEl">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="resultModalLabel"></h5>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"
               data-bs-dismiss="modal" aria-label="Close">
            <path
                d="M26.7484 14.6667L22.0001 19.415L17.2517 14.6667L14.6667 17.2517L19.4151 22L14.6667 26.7483L17.2517 29.3333L22.0001 24.585L26.7484 29.3333L29.3334 26.7483L24.5851 22L29.3334 17.2517L26.7484 14.6667ZM22.0001 3.66666C11.8617 3.66666 3.66675 11.8617 3.66675 22C3.66675 32.1383 11.8617 40.3333 22.0001 40.3333C32.1384 40.3333 40.3334 32.1383 40.3334 22C40.3334 11.8617 32.1384 3.66666 22.0001 3.66666ZM22.0001 36.6667C13.9151 36.6667 7.33341 30.085 7.33341 22C7.33341 13.915 13.9151 7.33332 22.0001 7.33332C30.0851 7.33332 36.6667 13.915 36.6667 22C36.6667 30.085 30.0851 36.6667 22.0001 36.6667Z"
                fill="black"/>
          </svg>
        </div>
        <div class="modal-body text-center">
          <img :src="getImg" class="w-100">
          <p v-if="selfInvited" class="text-center fs-5" v-html="$t('resultModal.selfInvitedChallengeOthers')">
          <p v-else class="text-center fs-5 mb-0" v-html="$t('resultModal.challengeOthers')">
          </p>
        </div>
        <div class="modal-footer d-flex flex-column">
          <div v-if="!selfInvited" class="d-flex w-100 justify-content-center">
            <a href="#" type="button"
               class="btn custom-btn btn-outline-success reverse d-flex align-items-center justify-content-center px-1"
               data-bs-dismiss="modal"
               aria-label="Close" @click="closeModal()">{{ $t("common.notNow") }}</a>
            <a :href="newGameLink" type="button"
               class="btn custom-btn btn-success d-flex align-items-center justify-content-center px-1">{{
                $t("common.yes")
              }}</a>
          </div>
          <div v-else class="d-flex w-100 flex-wrap justify-content-center">
            <a :href="newGameLink" type="button"
               class="btn custom-btn btn-success d-flex align-items-center justify-content-center px-3 mb-2">{{
                $t("common.challengeSomeone")
              }}</a>
            <a href="#" type="button"
               class="btn custom-btn btn-outline-success reverse d-flex align-items-center justify-content-center px-1 mb-2"
               data-bs-dismiss="modal"
               aria-label="Close" @click="playAgain()" :disabled="isInviting">
              {{ $t("common.playAgain") }}
              <span v-if="isInviting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </a>
          </div>
          <template v-if="this.success">
            <a href="#" class="text-decoration-none text-dark mt-3 fs-5" v-on:click="copyMessageToClipboard()">
              {{ $t("resultModal.wantToShareResult") }}
            </a>
            <div class="d-flex w-100 flex-wrap justify-content-center">
              <button type="button" class="btn custom-btn social-button fb p-2 mb-3" @click="shareToFb()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-facebook" viewBox="0 0 16 16">
                  <path
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
                Share
              </button>
              <button type="button" class="btn custom-btn social-button tw p-2 mb-3" @click="shareToTwitter()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter"
                     viewBox="0 0 16 16">
                  <path
                      d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                </svg>
                Tweet
              </button>
              <button type="button" class="btn custom-btn social-button wa p-2 mb-3" @click="shareToWhatsApp()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-whatsapp" viewBox="0 0 16 16">
                  <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                </svg>
                Share
              </button>
            </div>
          </template>
          <!--          <a href="https://livingmaples.com/funnector" type="button" class="btn custom-btn btn-success d-flex align-items-center px-1">Challenge others</a>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../scss/modal.scss";

export default {
  components: {},
  name: "ResultModal",
  props: {
    success: {type: Boolean},
    successLevel: {type: String},
    tries: {type: Number},
    playerName: {type: String},
    inviterName: {type: String},
    relationType: {type: String},
    onClose: {type: Function},
    selfInvited: {type: Boolean},
  },

  data() {
    return {
      isInviting: false,
      showShareItems: false
    }
  },

  computed: {
    getImg() {
      if (this.success) {
        return require("@/assets/" + this.successLevel + ".jpg")
      }

      return require("@/assets/lose.png")
    },

    getShareMessage() {
      return "I played Funnector and guessed the word in " + this.tries + " tries. YESSSS!\n" +
          "HIGHLY Recommended.\n" +
          "Funnector.com"
    },

    newGameLink() {
      return this.playerName !== "" ? "https://livingmaples.com/funnector?name=" + this.playerName : "https://livingmaples.com/funnector";
    }
  },

  methods: {
    shareResult() {
      let message = "";
      if (this.success) {
        message = this.$t("resultModal.successMessage");
      } else {
        message = this.$t("resultModal.failMessage");
      }

      setTimeout(function () {
        document.location.href = 'whatsapp://send?text=' + encodeURIComponent(message);
      }, 250);
    },

    closeModal() {
      this.onClose();
    },

    playAgain() {
      if (this.isInviting) {
        return;
      }

      this.isInviting = true;
      this.axios.post("/actions/game/random", {
        playerName: this.playerName,
        locale: this.$i18n.locale
      }).then((response) => {
        if (response.data.success) {
          setTimeout(function () {
            document.location.href = "https://funnector.com/game/" + response.data.data.gameId;
          }.bind(this), 250);
        }
      }).catch(() => {
        this.isInviting = false;
      });
    },

    copyMessageToClipboard() {
      this.showShareItems = true;

      const clipboardData = window.clipboardData || navigator.clipboard;
      if (clipboardData) {
        return clipboardData.writeText(this.getShareMessage);

      } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = this.getShareMessage;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },

    shareToFb() {
      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('lang');
      setTimeout(function () {
        window.open('https://www.facebook.com/dialog/feed?' +
            "&app_id=" + process.env.VUE_APP_FB_APP_ID +
            "&link=" + encodeURIComponent(process.env.VUE_APP_FB_HREF + "?lang=" + lang) +
            "&display=popup" +
            "&quote=" + encodeURIComponent(this.getShareMessage) +
            "&hashtag=#funnector");
        // document.location.href = 'https://www.facebook.com/dialog/feed?' +
        //     "&app_id=" + process.env.VUE_APP_FB_APP_ID +
        //     "&link=" + encodeURIComponent(process.env.VUE_APP_FB_HREF + "?lang=" + lang) +
        //     "&display=popup" +
        //     "&quote=" + encodeURIComponent(this.getShareMessage) +
        //     "&hashtag=#funnector";
      }.bind(this), 250);
    },

    shareToTwitter() {
      setTimeout(function () {
        window.open('https://twitter.com/intent/tweet?hashtags=funnector&text=' + encodeURIComponent(this.getShareMessage));
        // document.location.href = 'https://twitter.com/intent/tweet?hashtags=funnector&text=' + encodeURIComponent(this.getShareMessage);
      }.bind(this), 250);
    },

    shareToWhatsApp() {
      setTimeout(function () {
        window.open('whatsapp://send?text=' + encodeURIComponent(this.getShareMessage));
        // document.location.href = 'whatsapp://send?text=' + encodeURIComponent(this.getShareMessage);
      }.bind(this), 250);
    }
  }
};
</script>
