import Vue from 'vue'
import VueRouter from 'vue-router'
import {loadLanguageAsync} from '@/i18n';
import Home from '../components/Home'
import Play from '../components/Play'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/game/:gameId',
    name: 'Play',
    component: Play
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Funnector | Puzzle Game';

  // disable pwa feature for Play page
  if (to.name === 'Play') {
    const manifestLink = document.querySelector("link[rel='manifest']")
    manifestLink.parentNode.removeChild(manifestLink)
  }

  const lang = to.query.lang
  loadLanguageAsync(lang).then(() => next())
});

export default router
