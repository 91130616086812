<template>
  <body>
  <header class="d-flex flex-column" :class="largeHeader">
    <div v-if="isHome" class="presenter">
      <img src="/funnector/img/lm-logo-reverse.png">
      a Living Maples game
    </div>
    <div class="d-flex justify-content-between px-2 pb-2">
      <div class="d-flex align-items-center">
        <svg width="40" height="50" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"
             data-bs-toggle="modal" data-bs-target="#gameGuide">
          <path
              d="M18.7917 30.75H22.2084V27.3334H18.7917V30.75ZM20.5001 3.41669C11.0701 3.41669 3.41675 11.07 3.41675 20.5C3.41675 29.93 11.0701 37.5834 20.5001 37.5834C29.9301 37.5834 37.5834 29.93 37.5834 20.5C37.5834 11.07 29.9301 3.41669 20.5001 3.41669ZM20.5001 34.1667C12.9663 34.1667 6.83341 28.0338 6.83341 20.5C6.83341 12.9663 12.9663 6.83335 20.5001 6.83335C28.0338 6.83335 34.1667 12.9663 34.1667 20.5C34.1667 28.0338 28.0338 34.1667 20.5001 34.1667ZM20.5001 10.25C16.7247 10.25 13.6667 13.3079 13.6667 17.0834H17.0834C17.0834 15.2042 18.6209 13.6667 20.5001 13.6667C22.3792 13.6667 23.9167 15.2042 23.9167 17.0834C23.9167 20.5 18.7917 20.0729 18.7917 25.625H22.2084C22.2084 21.7813 27.3334 21.3542 27.3334 17.0834C27.3334 13.3079 24.2755 10.25 20.5001 10.25Z"
              fill="black"/>
        </svg>
      </div>
      <div class="d-flex align-items-center" @mousedown="cacheClearCounter" @mouseup="clearCache">Funnector</div>
      <div class="d-flex align-items-center">
<!--        <svg width="50" height="50" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                      d="M27.3334 18.7917V5.125H13.6667V15.375H3.41675V35.875H37.5834V18.7917H27.3334ZM17.0834 8.54167H23.9167V32.4583H17.0834V8.54167ZM6.83341 18.7917H13.6667V32.4583H6.83341V18.7917ZM34.1667 32.4583H27.3334V22.2083H34.1667V32.4583Z"-->
<!--                      fill="black"/>-->
<!--        </svg>-->
        <div class="dropdown d-flex align-items-center">
          <button class="btn dropdown-toggle btn-outline-light text-dark" type="button" id="changeLang" data-bs-toggle="dropdown" aria-expanded="false">
            {{ currentLang }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="changeLang">
            <li><a class="dropdown-item" href="#" v-on:click="switchLanguage('en')">English</a></li>
            <li><a class="dropdown-item" href="#" v-on:click="switchLanguage('es')">Español</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <div class="container-fluid container-fixed">
    <router-view v-if="!isLoading"></router-view>
  </div>
  </body>
</template>
<script>
import "./scss/app.scss";
import { loadLanguageAsync } from '@/i18n'

export default {
  name: "App",

  data() {
    return {
      isLoading: true,
      cacheClearButtonPressed: 0
    }
  },

  created() {
    this.$cookies.config(60 * 60 * 24 * 30, '', '', process.env.NODE_ENV === 'production');
    this.getUser();
  },

  computed: {
    isHome() {
      return this.$route.name === "Home";
    },

    largeHeader() {
      return this.isHome ? "large" : "";
    },

    currentLang() {
      return this.$i18n.locale.toUpperCase();
    }
  },

  methods: {
    cacheClearCounter() {
      this.cacheClearButtonPressed++;
      return false;
    },

    clearCache() {
      if (this.cacheClearButtonPressed >= 5) {
        this.$cookies.keys().forEach((k) => {
          this.$cookies.remove(k);
        });

        this.cacheClearButtonPressed = 0;
        location.reload();
        return false;
      }
    },

    async getUser() {
      if (this.$cookies.isKey("uid") && this.$cookies.get("uid") !== "") {
        this.axios.defaults.headers.common['Authorization'] = this.$cookies.isKey("uid") ? this.$cookies.get("uid") : "";
        this.isLoading = false;
        return;
      }

      this.axios.get("/actions/user").then((response) => {
        if (response.data.success) {
          this.$cookies.set("uid", response.data.data.userId);
          this.axios.defaults.headers.common['Authorization'] = this.$cookies.isKey("uid") ? this.$cookies.get("uid") : "";
          this.isLoading = false;
        }
      });
    },

    switchLanguage(lang) {
      loadLanguageAsync(lang)
    }
  }
};
</script>
