<template>
  <div class="modal fade" id="gameGuide" tabindex="-1" aria-labelledby="gameGuideLabel" aria-hidden="true"
       data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="gameGuideLabel"></h5>
          <!--          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"-->
          <!--               data-bs-dismiss="modal" aria-label="Close">-->
          <!--            <path-->
          <!--                d="M26.7484 14.6667L22.0001 19.415L17.2517 14.6667L14.6667 17.2517L19.4151 22L14.6667 26.7483L17.2517 29.3333L22.0001 24.585L26.7484 29.3333L29.3334 26.7483L24.5851 22L29.3334 17.2517L26.7484 14.6667ZM22.0001 3.66666C11.8617 3.66666 3.66675 11.8617 3.66675 22C3.66675 32.1383 11.8617 40.3333 22.0001 40.3333C32.1384 40.3333 40.3334 32.1383 40.3334 22C40.3334 11.8617 32.1384 3.66666 22.0001 3.66666ZM22.0001 36.6667C13.9151 36.6667 7.33341 30.085 7.33341 22C7.33341 13.915 13.9151 7.33332 22.0001 7.33332C30.0851 7.33332 36.6667 13.915 36.6667 22C36.6667 30.085 30.0851 36.6667 22.0001 36.6667Z"-->
          <!--                fill="black"/>-->
          <!--          </svg>-->
        </div>
        <div class="modal-body">
          <p>
            <strong>{{ $t('common.hello', {name: playerName}).trim() }}!</strong><br>
            <template v-if="selfInvited">
              {{ $t('gameGuide.selfInvitation') }}
            </template>
            <template v-else-if="inviterName !== ''">
              {{ $t('gameGuide.namedInvitation', {relationType: relationType, inviterName: inviterName}) }}
            </template>
            <template v-else>
              {{ $t('gameGuide.unnamedInvitation') }}
            </template>
          </p>
          <p class="d-flex flex-row justify-content-center">
            <span class="d-flex align-items-center justify-content-center"
                  style="margin: auto 2px; background: #FFFFFF; width: 35px; height: 35px; display: inline-block; border: 1px solid #939393;">M</span>
            <span class="d-flex align-items-center justify-content-center"
                  style="margin: auto 2px; background: #65AC62; width: 35px; height: 35px; display: inline-block; color: #FFFFFF">A</span>
            <span class="d-flex align-items-center justify-content-center"
                  style="margin: auto 2px; background: #C8B556; width: 35px; height: 35px; display: inline-block; color: #FFFFFF">P</span>
            <span class="d-flex align-items-center justify-content-center"
                  style="margin: auto 2px; background: #787B80; width: 35px; height: 35px; display: inline-block; color: #FFFFFF">L</span>
            <span class="d-flex align-items-center justify-content-center"
                  style="margin: auto 2px; background: #FFFFFF; width: 35px; height: 35px; display: inline-block; border: 1px solid #939393;">E</span>
          </p>

          <div class="d-flex">
            <span style="background: #787B80; min-width: 35px; height: 35px; display: inline-block;"></span>
            <p class="px-1" v-html="$t('gameGuide.wrongLetter')"></p>
          </div>
          <div class="d-flex">
            <span style="background: #C8B556; min-width: 35px; height: 35px; display: inline-block;"></span>
            <p class="px-1" v-html="$t('gameGuide.misplacedLetter')"></p></div>
          <div class="d-flex">
            <span style="background: #65AC62; min-width: 35px; height: 35px; display: inline-block;"></span>
            <p class="px-1" v-html="$t('gameGuide.correctLetter')"></p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn custom-btn btn-success p-2" data-bs-dismiss="modal">{{ $t('common.letsGo') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../scss/modal.scss";
import {Modal} from "bootstrap";

export default {
  components: {},
  name: "GameGuide",
  props: ['playerName', 'inviterName', 'relationType', 'selfInvited'],

  mounted() {
    if (!this.$cookies.isKey("gameGuidedViewed")) {
      const guideModal = new Modal(document.getElementById('gameGuide'));
      const modalToggle = document.getElementById('gameGuide');
      guideModal.show(modalToggle);
      this.$cookies.set("gameGuidedViewed", true);
    }
  }
};
</script>
