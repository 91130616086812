<template>
  <div class="wizard px-3" ref="wizard">
    <div v-if="!isInInviterMode" class="d-flex justify-content-center align-items-center flex-column">
      <p class="mb-4" v-html="$t('home.modeSelectionDescription')"></p>
      <div v-if="!isInInviterMode" class="d-flex align-items-center">
        <button class="custom-btn next btn btn-success" @click="setMode(false)" :disabled="isInviting">
          {{ $t("home.playNow") }}
          <span v-if="isInviting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button class="custom-btn next btn btn-success" @click="setMode(true)">{{ $t("home.challengeSomeone") }}
        </button>
      </div>
    </div>
    <template v-else>
      <div v-if="currentStep === 1" class="mb-4">
        <p class="w-100 text-center fw-bolder">{{ $t("common.helloThere") }}</p>
        <p class="label mb-2">{{ $t("home.nameLabel") }}</p>
        <input class="form-control" type="text" v-model="inviterName" ref="inviterName">
        <!--      <span v-if="isNameEmpty" class="text-danger w-100 text-center mt-1 d-block">-->
        <!--          Please enter your name-->
        <!--      </span>-->

        <span class="w-100 mt-2">
          {{ $t("home.nameHint") }}
      </span>
      </div>
      <div v-if="currentStep === 2" class="mb-4">
        <p class="w-100 text-center fw-bolder">{{ $t("home.wordDescription") }}</p>
        <div class="board entry d-flex flex-column">
          <div>
            <p class="label mb-1">{{ $t("home.wordLabel") }}</p>
            <template v-for="i in wordLength">
              <input class="form-control" type="text" maxlength="1" minlength="1" :key="i" ref="word" :tabindex="i"
                     v-on:click="changeInputIndex(i - 1)" v-model="word[i - 1]" :pattern="validLettersRegex">
            </template>
          </div>
          <span v-if="isWordEmpty" class="text-danger w-100 text-center mt-1">
          {{ $t("home.wordError") }}
        </span>
        </div>
      </div>
      <div v-if="currentStep === 3" class="mb-4">
        <p class="label mb-2">{{ $t("home.hintDescription") }}<br>{{ $t("home.hintLabel") }}</p>
        <div class="hint-box p-2">
          <textarea class="form-control" rows="4" v-model="hint" ref="hint" tabindex="6"></textarea>
          <div class="w-100 text-end">
            <span>{{ hintLength }}/{{ maxHintLength }}</span>
          </div>
        </div>
        <span v-if="hintExceeded" class="text-danger w-100 text-center mt-1 d-block">
        {{ $t("home.hintError", {maxHintLength: maxHintLength}) }}
      </span>
      </div>
      <div v-if="currentStep === 4" class="mb-4">
        <p class="label mt-1 mb-2">{{ $t("home.playerRelationLabel") }}</p>
        <div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeFriend"
                   value="Friend"
                   v-model="relationType">
            <label class="form-check-label" for="relationTypeFriend">{{ $t("home.relationFriend") }}</label>
          </div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeSpouse"
                   value="Spouse"
                   v-model="relationType">
            <label class="form-check-label" for="relationTypeSpouse">{{ $t("home.relationSpouse") }}</label>
          </div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeChild" value="Child"
                   v-model="relationType">
            <label class="form-check-label" for="relationTypeChild">{{ $t("home.relationChild") }}</label>
          </div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeParent"
                   value="Parent"
                   v-model="relationType">
            <label class="form-check-label" for="relationTypeParent">{{ $t("home.relationParent") }}</label>
          </div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeGrandchild"
                   value="Grandchild" v-model="relationType">
            <label class="form-check-label" for="relationTypeGrandchild">{{ $t("home.relationGrandchild") }}</label>
          </div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeGrandparent"
                   value="Grandparent" v-model="relationType">
            <label class="form-check-label" for="relationTypeGrandparent">{{ $t("home.relationGrandparent") }}</label>
          </div>
          <div class="col-5 form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="relationTypeOptions" id="relationTypeSibling"
                   value="Sibling" v-model="relationType">
            <label class="form-check-label" for="relationTypeSibling">{{ $t("home.relationSibling") }}</label>
          </div>
        </div>
        <p class="label mb-2 mt-4">{{ $t("home.playerNameLabel") }}</p>
        <input class="form-control" type="text" v-model="playerName" :maxlength="maxPlayerNameLength" ref="playerName">
        <span class="w-100 mt-2">
          {{ $t("home.playerNameHint") }}
      </span>
      </div>
      <div v-if="currentStep === 5" class="mb-4">
        <p class="mb-2">{{ $t("home.youAreReady") }}</p>
        <p class="mb-2" v-if="playerName !== ''" v-html="$t('home.readyToInviteWithName', {
          relationType: relationType,
          playerName: playerName,
          theWord: theWord
        })">
        </p>
        <p class="mb-2" v-else v-html="$t('home.readyToInvite', {
          theWord: theWord
        })">
        </p>
      </div>
      <div v-if="currentStep === 6" class="mb-4">
        <p v-if="playerName !== ''" class="mb-4">
          {{ $t("home.invitedWithName", {relationType: relationType, playerName: playerName, theWord: theWord}) }}
        </p>
        <p v-else class="mb-4">
          {{ $t("home.invited", {theWord: theWord}) }}
        <p>
          {{ $t("home.playWithSomeoneElse") }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <template v-if="isLastStep || !isStartedNewGame">
          <template v-if="!isWantToContinue">
            <button class="custom-btn previous btn btn-outline-success" @click="showThanks()">{{ $t("common.notNow") }}
            </button>
            <button class="custom-btn next btn btn-success" @click="wantToContinue()">{{ $t("common.yes") }}
            </button>
          </template>
          <template v-else>
            <button class="custom-btn next btn btn-success" @click="newGame(true)">{{ $t("home.theSameWord") }}
            </button>
            <button class="custom-btn next btn btn-success" @click="newGame()">{{ $t("home.newWord") }}
            </button>
          </template>
        </template>
        <template v-else>
          <button v-if="!isFirstStep" class="custom-btn previous btn btn-outline-success"
                  @click="previousStep()">
            {{ $t("common.back") }}
          </button>
          <button v-if="!isInviteStep" class="custom-btn next btn btn-success"
                  @click="nextStep()">{{ $t("common.next") }}
          </button>
          <button v-if="isInviteStep" class="next custom-btn btn btn-success" v-on:click="invite()"
                  :disabled="isInviting">
            {{ $t("home.invite") }}
            <span v-if="isInviting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </template>
      </div>
      <HomeGuide :click-action="userGuideViewed" :name="inviterName"></HomeGuide>
      <WelcomeBack :click-action="userGuideViewed" :name="inviterName"></WelcomeBack>
      <Thanks></Thanks>
    </template>
  </div>
</template>

<script>
import "../scss/home.scss";
import HomeGuide from "./HomeGuide";
import WelcomeBack from "./WelcomeBack";
import Thanks from "./Thanks";
import {Modal} from 'bootstrap';
import {getName, setName, gameSent, isGameSent, getLastGame, isInviterAlreadyVisited} from './Storage';

export default {
  name: "Home",
  components: {
    HomeGuide,
    WelcomeBack,
    Thanks,
  },
  data: () => ({
    isInInviterMode: false,
    totalStep: 6,
    wordLength: 5,
    maxHintLength: 15,
    hintLength: 0,
    maxPlayerNameLength: 32,
    currentStep: 1,
    word: [],
    inputIndex: 0,
    hint: "",
    isWordEmpty: false,
    isNameEmpty: false,
    hintExceeded: false,
    playerName: "",
    isInviting: false,
    inviterName: "",
    validationError: {},
    guideShown: false,
    relationType: "",
    isWantToContinue: false,
    isStartedNewGame: true,
  }),

  computed: {
    validLettersRegex() {
      switch (this.$i18n.locale) {
        case 'es':
          // return "^[a-zñáéíóúü]$/i"
          return "^[a-zA-ZñÑ]$"
        default:
          return "^[a-zA-Z]$"
      }
    },

    isFirstStep() {
      return this.currentStep === 1;
    },

    isLastStep() {
      return this.currentStep === this.totalStep;
    },

    isInviteStep() {
      return this.currentStep === 5;
    },

    userWordLength() {
      return this.word.filter(Boolean).length;
    },

    theWord() {
      return this.word.join("").toUpperCase();
    }
  },

  watch: {
    word: function () {
      if (this.userWordLength === this.wordLength) {
        this.isWordEmpty = false;
      }
    },
    hint: function (val) {
      const hintLength = val === "" ? 0 : val.trim().split(' ').length;
      if (hintLength > this.maxHintLength) {
        this.hintExceeded = true;
      } else {
        this.hintExceeded = false;
      }

      this.hintLength = hintLength;
    },
    currentStep: function (v) {
      if (v === 2 && !this.guideShown) {
        this.guideShown = true;

        const m = isInviterAlreadyVisited() ? "welcomeBack" : "gameGuide";
        const modal = new Modal(document.getElementById(m));
        const modalToggle = document.getElementById(m);
        modal.show(modalToggle);
      }
    },
    relationType: function (v) {
      if (v !== "" && this.currentStep === 4) {
        this.$refs.playerName.focus()
      }
    }
  },

  mounted() {
    window.addEventListener("keyup", e => {
      if (e.keyCode === 8) {
        if (this.inputIndex === 0) {
          return;
        }

        if (this.currentStep === 2) {
          if (this.word[this.inputIndex] !== undefined) {
            this.word[this.inputIndex] = "";
          }

          this.inputIndex--;
          this.forceInput();
          // this.word = this.word.slice(0, -1);
        }

        return;
      } else if (e.keyCode === 13) {
        if (this.isLastStep) {
          this.invite();
        } else if (this.currentStep !== 3) {
          this.nextStep();
        }

        return;
      }

      if (this.currentStep === 2) {
        if (e.target.value.length > 1) {
          e.target.value = e.target.value.substr(0, 1);
        }

        let regex = new RegExp(this.validLettersRegex);
        if (!regex.test(e.target.value)) {
          e.target.value = "";
          return;
        }

        this.letterInserted(e.target.value);
        // if (e.target.value !== "") {
        //   this.inputIndex++;
        //   this.forceInput();
        // }
      }
    });

    if (this.isFirstStep) {
      if (this.$route.query.name !== undefined && this.$route.query.name !== "" && !isInviterAlreadyVisited() && getName() === "") {
        setName(this.$route.query.name);
        this.inviterName = this.$route.query.name;
      } else {
        this.inviterName = getName();
      }

      if (this.inviterName.toLowerCase() === "null") {
        this.inviterName = "";
      }

      if (this.inviterName !== "") {
        this.currentStep = 2;
      }
    }

    if (isGameSent()) {
      this.currentStep = 6;
      const lastGame = getLastGame();
      if (!lastGame) {
        this.currentStep = 1;
      } else {
        this.word = lastGame.word;
        this.hint = lastGame.hint;
        this.playerName = lastGame.playerName;
        this.relationType = lastGame.relationType;
        this.isStartedNewGame = false;
      }
    }
  },

  methods: {
    setMode(isInInviterMode) {
      this.isInInviterMode = isInInviterMode;
      if (!isInInviterMode) {
        this.isInviting = true;
        this.axios.post("/actions/game/random", {
          playerName: this.inviterName,
          locale: this.$i18n.locale
        }).then((response) => {
          if (response.data.success) {
            setTimeout(function () {
              document.location.href = "https://funnector.com/game/" + response.data.data.gameId;
            }.bind(this), 250);
          }
        }).catch(() => {
          this.isInviting = false;
        });
      }
    },

    async nextStep() {
      if (this.currentStep === this.totalStep) {
        return;
      }

      if (this.currentStep === 1) {
        // if (this.inviterName === "") {
        //   this.isNameEmpty = true;
        //   this.forceInput();
        //   return;
        // }

        setName(this.inviterName);
      }

      if (this.currentStep === 2 && this.userWordLength < this.wordLength) {
        this.isWordEmpty = true;
        this.forceInput();
        return;
      }

      if (this.currentStep === 3 && this.hintExceeded) {
        return;
      }

      this.currentStep++;
      await this.$nextTick();
      this.forceInput();
    },

    async previousStep() {
      if (this.isFirstStep) {
        return;
      }

      this.currentStep--;
      await this.$nextTick();

      if (this.currentStep === 2) {
        this.changeInputIndex(this.userWordLength - 1);
      }

      this.forceInput();
    },

    invite() {
      this.isInviting = true;
      let rt = this.relationType;
      switch (this.relationType) {
        case "Friend":
          rt = this.$t("home.relationFriend");
          break;
        case "Spouse":
          rt = this.$t("home.relationSpouse");
          break;
        case "Child":
          rt = this.$t("home.relationParent");
          break;
        case "Parent":
          rt = this.$t("home.relationChild");
          break;
        case "Grandchild":
          rt = this.$t("home.relationGrandparent");
          break;
        case "Grandparent":
          rt = this.$t("home.relationGrandChild");
          break;
        case "Sibling":
          rt = this.$t("home.relationSibling");
          break;
      }

      this.axios.post("/actions/game", {
        word: this.word.join(""),
        hint: this.hint,
        playerName: this.playerName,
        relationType: rt,
        inviterName: this.inviterName,
        locale: this.$i18n.locale
      }).then((response) => {
        if (response.data.success) {
          let message = "https://youtu.be/N0cC06D0UDk\n\n";
          if (this.playerName === "") {
            message += this.$t("home.invitationMessage", {link: "https://funnector.com/game/" + response.data.data.gameId});
          } else {
            message += this.$t("home.invitationMessage", {
              playerName: this.playerName,
              link: "https://funnector.com/game/" + response.data.data.gameId
            });
          }

          setTimeout(function () {
            document.location.href = 'whatsapp://send?text=' + encodeURIComponent(message);
            this.isInviting = false;
            gameSent({
              word: this.word,
              hint: this.hint,
              playerName: this.playerName,
              relationType: this.relationType
            });
            this.nextStep();
          }.bind(this), 250);
        }
      });
    },

    wantToContinue() {
      this.isWantToContinue = true;
    },

    newGame(sameWord = false) {
      this.isStartedNewGame = true;
      this.playerName = "";
      this.relationType = null;
      this.isWantToContinue = false;
      if (sameWord) {
        this.currentStep = 4;
        return;
      }

      this.currentStep = 2;
      this.word = [];
      this.inputIndex = 0;
      this.hint = "";
    },
    changeInputIndex(idx) {
      this.inputIndex = idx;
    },

    letterInserted(letter) {
      if (this.userWordLength === this.wordLength) {
        return false;
      }

      // if (this.word[this.inputIndex] === undefined) {
      //   return;
      // }

      let regex = new RegExp(this.validLettersRegex);
      if (!regex.test(letter)) {
        this.word[this.inputIndex] = "";
        return;
      }

      if (this.userWordLength < this.wordLength) {
        this.inputIndex++;
        this.forceInput();
      }
    },

    userGuideViewed() {
      this.forceInput();
    },

    forceInput() {
      if (this.currentStep === 1) {
        this.$refs.inviterName.focus();
      } else if (this.currentStep === 2) {
        if (this.$refs.word[this.inputIndex] === undefined) {
          return;
        }

        this.$refs.word[this.inputIndex].focus();
      } else if (this.currentStep === 3) {
        this.$refs.hint.focus();
      }
      // } else if (this.currentStep === 4) {
      //   this.$refs.playerName.focus();
      // }
    },

    showThanks() {
      const modal = new Modal(document.getElementById("thanksModal"));
      const modalToggle = document.getElementById("thanksModal");
      modal.show(modalToggle);
    },
  }
};
</script>
