import Vue from 'vue'

const STORAGE_PREFIX = "funnector_";
function getKey(key) {
    return STORAGE_PREFIX + key;
}

function getName() {
    return Vue.$cookies.isKey(getKey("InviterName")) ? Vue.$cookies.get(getKey("InviterName")) : "";
}

function setName(name) {
    Vue.$cookies.set(getKey("InviterName"), name);
}

function gameSent(data) {
    Vue.$cookies.set(getKey("GameSent"), true);
    Vue.$cookies.set(getKey("LastGame"), data);
}

function isGameSent() {
    return Vue.$cookies.isKey(getKey("GameSent")) ? Vue.$cookies.get(getKey("GameSent")) : false;
}

function getLastGame() {
    return Vue.$cookies.isKey(getKey("LastGame")) ? Vue.$cookies.get(getKey("LastGame")) : null;
}

function isInviterAlreadyVisited() {
    return Vue.$cookies.isKey(getKey("inviterAlreadyVisited")) ? Vue.$cookies.get(getKey("inviterAlreadyVisited")) : false;
}

function setInviterVisited() {
    Vue.$cookies.set(getKey("inviterAlreadyVisited"), true);
}

export {
    getName,
    setName,
    gameSent,
    isGameSent,
    getLastGame,
    setInviterVisited,
    isInviterAlreadyVisited
}